<template>
  <div class="header-wrap">
    <div class="header-left">
      <div class="header-left-item">
        <img class="xs-hide" src="../assets/images/pc_left.svg" alt="left">
        <img class="xs-show" src="../assets/images_mobile/nav-l.svg" alt="left">
      </div>
    </div>
    <div class="header-right">
      <div class="header-right-item">
        <img class="xs-hide" src="../assets/images/pc_right.svg" alt="right">
        <img class="xs-show" src="../assets/images_mobile/nav-r.svg" alt="right">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Header'
}
</script>

<style scoped lang="less">
.header-wrap{
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  box-shadow: 0 1px 2px 0 rgba(50,50,71,0.08), inset 0 -1px 0 0 #E2E3E9;
  height: 64px;
  line-height: 64px;
  padding: 0 32px;
}
.header-left-item,.header-right-item{
  display: flex;
  align-items: center;
}
@media screen and(max-width: 750px) {
  .header-wrap{
    padding: 0 16px;
  }
}
</style>
