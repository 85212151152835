import Vue from "vue";
//导入步骤1新建的vue组件
import messageBox from "./index.vue";

let MessageBox = ({ ok, cancel, ...rest }) => {
  let myComp = Vue.extend(messageBox);
  let vm = new myComp({
    el: document.createElement("div"),
    data: {
      ...rest,
    },
    methods: {
      handleOk() {
        if (typeof ok === "function") {
          ok(vm.$data);
        }
        document.body.removeChild(vm.$el);
      },
      handleCancel() {
        document.body.removeChild(vm.$el);
      },
    },
  });
  document.body.appendChild(vm.$el);
};

export default {
  install(Vue) {
    Vue.prototype.message = MessageBox;
  },
};
