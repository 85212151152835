<template>
  <div class="main-wrap">
    <div class="common-header">
      <base-header></base-header>
    </div>
    <div class="main-content">
      <div v-if="$route.meta.keepActive">
        <keep-alive>
          <router-view v-if="isRouterAlive"/>
        </keep-alive>
      </div>
      <div v-else>
        <router-view v-if="isRouterAlive"/>
      </div>
    </div>
  </div>
</template>
<script>
import BaseHeader from  '@/components/BaseHeader'
export default {
  provide () {
    return {
      reload: this.reload
    }
  },
  components: {
    BaseHeader
  },
  data() {
    return {
      isRouterAlive: true
    }
  },
  created() {
  },
  methods: {
    reload () {
      this.isRouterAlive = false
      this.$nextTick(function () {
        this.isRouterAlive = true
      })
    }
  }
}
</script>

<style scoped lang="less">
 .main-content{
   width: 1200px;
   margin: 0 auto;
 }
 @media screen and (max-width: 750px) {
   .main-content{
     width: 100%;
     padding: 0 16px 100px;
     margin: 0 auto;
   }
 }
</style>
