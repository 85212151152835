import Vue from 'vue'
import VueRouter from 'vue-router'
import BaseLayout from '@/layouts/BaseLayout'

Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'demo',
    component: BaseLayout,
    redirect: '/demo/cart',
    children: [
      {
        path: '/demo/cart',
        name: 'ShoppingCart',
        component: () => import(/* webpackChunkName: "about" */ '../views/ShoppingCart.vue')
      },
      {
        path: '/demo/info',
        name: 'CustomerInfo',
        component: () => import(/* webpackChunkName: "about" */ '../views/CustomerInfo.vue')
      },
      {
        path: '/demo/shipping',
        name: 'ShippingInfo',
        component: () => import(/* webpackChunkName: "about" */ '../views/ShippingInfo.vue')
      },
      {
        path: '/demo/selection',
        name: 'PaymentSelection',
        component: () => import(/* webpackChunkName: "about" */ '../views/PaymentSelection.vue')
      },
      {
        path: '/demo/result',
        name: 'PaymentResult',
        component: () => import(/* webpackChunkName: "about" */ '../views/PaymentResult.vue')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
